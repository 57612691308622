import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    token: "",
    user: {},
    movil: "",
    currentServices: [],
    currentPatient: null,
    currentService: null,
    currentPatientInfo: {},
    historiasClinicas: {},
  },
  mutations: {
    token(state, value) {
      state.token = value;
    },
    user(state, value) {
      state.user = value;
    },
    movil(state, value) {
      state.movil = value;
    },
    currentServices(state, value) {
      state.currentServices = value;
    },
    currentPatient(state, value) {
      state.currentPatient = value;
    },
    currentService(state, value) {
      state.currentService = value;
    },
    patientInfo(state, value) {
      state.currentPatientInfo = value;
    },
    initHistoriaClinica(state, consecutivo) {
      if(! state.historiasClinicas[consecutivo]) {
        state.historiasClinicas[consecutivo] = {};
      }
    },
    setKeyToHistory(state, value) {
      if(value.validate) {
        if(!state.historiasClinicas[value.consecutivo][value.key]) {
          state.historiasClinicas[value.consecutivo][value.key] = value.value;
        }
      } else {
        state.historiasClinicas[value.consecutivo][value.key] = value.value;
      }
    }
    // TODO:
    // Make action to clean store
  },
  actions: {},
  modules: {},
});
