<template>
  <div>
    <v-app-bar color="deep-purple" dark>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <v-toolbar-title class="d-flex w-100 justify-center"
        >Historias Clinicas RMV</v-toolbar-title
      >
      <v-menu offset-y v-if="this.$route.name == 'Home'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="selectOption(option.value)"
            v-for="(option, index) in options"
            :key="index"
          >
            <v-list-item-title>{{ option.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="this.$route.name != 'Login'"
      v-model="drawer"
      absolute
      temporary
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item :to="{ name: 'Home' }">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Account</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog
      persistent
      v-model="showModalSelectMovil"
      transition="dialog-bottom-transition"
      max-width="200"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar color="primary" dark>Seleccionar movil</v-toolbar>
          <v-card-text>
            <v-text-field
              v-model="MovilSelected"
              hide-details
              single-line
              type="number"
            />
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn text @click="dialog.value = false">Close</v-btn>
            <v-btn
              text
              @click="
                dialog.value = false;
                saveMovilSelected();
              "
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import services from "@/modules/currentServices";
export default {
  name: "MainNav",

  data: () => ({
    drawer: false,
    group: null,
    showModalSelectMovil: false,
    options: [
      {
        title: "Seleccionar Movil",
        value: 1,
      },
    ],
    MovilSelected: null,
  }),
  created() {
    this.MovilSelected = this.$store.state.movil;
  },
  methods: {
    selectOption(option) {
      switch (option) {
        case 1:
          this.showModalSelectMovil = true;
          break;

        default:
          break;
      }
    },
    saveMovilSelected() {
      this.$store.commit("movil", this.MovilSelected);
      services.getCurrentServices().then((response) => {
        console.log(response);
        console.log("hola");
      });
    },
  },
};
</script>
<style scoped>
header {
  box-shadow: none !important;
}
</style>
