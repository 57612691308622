import Vue from "vue";
import App from "./App.vue";
import VueSignature from "vue-signature-pad";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;
Vue.use(VueSignature);

Vue.filter("triaje", function (value) {
  if (!value) return "";
  const newVal = value.toLowerCase();
  switch (newVal) {
    case "a":
      return "AMARILLO";
    case "v":
      return "VERDE";
    case "r":
      return "ROJO";
    case "p":
      return "AZUL";
    default:
      break;
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
