import axios from "axios";

import vuex from "@/store";
const local = {
  createAxiosInstance() {
    local.apiInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
    });
  },
};
const currentService = {
  set(id, consecutive) {
    vuex.commit("currentPatient", id);
    vuex.commit("currentService", consecutive);
  },
  getPatientData(consecutive){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get(`/informacion-paciente/${consecutive}`, {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          vuex.commit("patientInfo", response.data[0]);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  registrarHoraLlegada(consecutive){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .post(`/registrar-hora/${consecutive}`, {}, {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          resolve(response.data.resultado);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getHourServer(){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get('/hour', {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveImage(payload){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .post('/guardar-firma', payload, {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getDiagnosticos(input){
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get(`/diagnosticos?texto=${input}`, {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
// self invoking function
(() => {
  local.createAxiosInstance();
})();
export default currentService;
