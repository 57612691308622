<template>
  <div class="resumen-consecutivo-container pt-5">
    <p>
        Consecutivo: {{ currentService }}
    </p>
    <p>
        {{ (currentPatientInfo.bennm1 ?? '') + ' ' + (currentPatientInfo.bennm2 ?? '') + ' ' + (currentPatientInfo.benap1 ?? '') + ' ' + (currentPatientInfo.benap2 ?? '') }}
    </p>
    <p> 
      {{ currentPatientInfo.bendoc ?? '' }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ResumenConsecutivo",
  computed: mapState({
    currentPatientInfo: (state) => state.currentPatientInfo,
    currentService: (state) => state.currentService
  }),
  methods: {

  }
};
</script>
<style lang="scss">
.resumen-consecutivo-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #efefef;
  p {
    margin-bottom: 0.3rem;
    font-weight: bold;
  }
}
</style>