import axios from "axios";

import vuex from "@/store";
const local = {
  createAxiosInstance() {
    local.apiInstance = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
    });
  },
};
const services = {
  getCurrentServices() {
    return new Promise((resolve, reject) => {
      local.apiInstance
        .get(`/historias-activas/${vuex.state.movil}`, {
          headers: {
            Authorization: `Bearer ${vuex.state.token}`,
          },
        })
        .then((response) => {
          vuex.commit("currentServices", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
// self invoking function
(() => {
  local.createAxiosInstance();
})();
export default services;
