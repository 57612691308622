<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-expansion-panels>
      <v-expansion-panel v-for="(service, i) in currentServices" :key="i">
        <v-expansion-panel-header
          :color="service.reqreg == 1 ? '#8080808c' : 'white'"
        >
          {{ service.reqcsc }}
          <v-chip
            text-color="white"
            class="ma-2"
            :style="{ background: color(service.reqcda) }"
          >
            {{ service.reqcda | triaje }}
          </v-chip>
        </v-expansion-panel-header>
        <v-expansion-panel-content
          :color="service.reqreg == 1 ? '#8080808c' : 'white'"
        >
          <span>
            Triaje:
            <v-chip
              text-color="white"
              class="ma-2"
              :style="{ background: color(service.reqcda) }"
            >
              {{ service.reqcda | triaje }}
            </v-chip>
          </span>
          <span>
            Historia Digital:
            {{ service.reqreg == 1 }}
          </span>
          <v-btn depressed color="primary" @click="verPaciente(service)">
            Ver Datos Paciente
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-app>
</template>

<script>
import services from "@/modules/currentServices";
import currentService from "@/modules/currentService";
import { mapState } from "vuex";
export default {
  name: "Home",
  components: {},
  created() {
    services.getCurrentServices();
  },
  computed: mapState({
    currentServices: (state) => state.currentServices,
  }),
  methods: {
    color(char) {
      const newChar = char.toLowerCase();
      switch (newChar) {
        case "a":
          return "#FFEB3B";
        case "v":
          return "#4CAF50";
        case "r":
          return "#F44336";
        case "p":
          return "#03A9F4";
        default:
          break;
      }
    },
    verPaciente(service) {
      currentService.set(service.id_ben, service.reqcsc);
      this.$router.push({
        name: "DatosPaciente",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-chip {
  max-width: 100px;
  text-align: center;
  display: flex;
  justify-content: center;
}
</style>
